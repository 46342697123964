import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function About() {
  return (
    <Layout>
      <SEO 
        title="About Compute Software" 
        description="Compute Software is a leader in cloud cost optimization.  With our platform for cloud cost optimization, we deliver enterprises running on the cloud the deepest optimizations and greatest cloud cost savings."
      />
      <Helmet></Helmet>
      <section className="about-hero sub-hero centered bg-img jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">About Us</h1>
        </div>
      </section>
      <section className="who-we-are sub-general-content centered bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="section-title">Who We Are</h3>
              <p>We are a highly-driven team with a goal to unlock business value from the cloud. By the choices we make and the actions we take, we are reducing wasteful spending and increasing productivity and wealth.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="sub-general-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="section-title">Join the Compute Software team</h3>
              <p>We are looking for passionate, driven people looking to make a direct and lasting impact.</p>
              <a href="/careers/" className="btn btn-outline-primary">See Openings &#10230;</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}